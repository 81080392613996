import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";
import { getCurrentUser } from "../firebase";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    component: () => import("../views/About.vue"),
  },
  {
    path: "/carbs-cycling",
    name: "CarbsCycling",
    component: () => import("../views/CarbsCycling.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/carbs-cycling-rule",
    name: "CarbsCyclingRule",
    component: () => import("../views/CarbsCyclingRule.vue"),
    meta: {
      auth: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

router.beforeEach(async (to, from, next) => {
  const needAuth = to.matched.some((p) => p.meta.auth);
  if (needAuth && !(await getCurrentUser())) {
    next("/");
  }
  next();
});

export default router;

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Top from "@/components/Top.vue";
import "@/assets/global.css";
import "@/firebase";

const app = createApp(App);
app.component("Top", Top);
app.use(router).use(store).mount("#app");

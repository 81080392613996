<template>
  <Top></Top>

  <div class="uk-grid-divider uk-margin-large-top" uk-grid>
    <Personal :showSave="isLogin"></Personal>

    <div class="uk-width-expand@m uk-margin-left uk-margin-right uk-margin-remove-left@m uk-margin-remove-right@m">
      <div uk-alert>
        <h3>BMI</h3>
        <p>{{ BMI }}</p>
        <span
          class="uk-position-absolute uk-position-top-right uk-position-small"
          uk-icon="info"
          uk-tooltip
          title="體重(公斤) / 身高(公尺)<sup>2</sup>"
        ></span>
      </div>

      <div uk-alert>
        <h3>BMR</h3>
        <p>{{ BMR }}</p>
        <span
          class="uk-position-absolute uk-position-top-right uk-position-small"
          uk-icon="info"
          uk-tooltip
          title="<div><b>女性</b><br />10 x 體重(公斤) + 6.25 x 身高(公分) – 5 x 年齡 – 161</div><br />
                <div><b>男性</b><br />10 x 體重(公斤) + 6.25 x 身高(公分) – 5 x 年齡 + 5</div>"
        ></span>
      </div>

      <div uk-alert>
        <h3>TDEE</h3>
        <p>{{ DisplayTDEE }}</p>
        <span
          class="uk-position-absolute uk-position-top-right uk-position-small"
          uk-icon="info"
          uk-tooltip
          title="<dd>幾乎沒運動 BMR x 1.2</dd>
                <dd>每週 1 - 2 天 BMR x 1.375</dd>
                <dd>每週 3 - 4 天 BMR x 1.55</dd>
                <dd>每週 5 - 6 天 BMR x 1.725</dd>
                <dd>每天高強度運動 BMR x 1.9</dd>"
        ></span>
      </div>
    </div>

    <div
      class="
        uk-width-expand uk-margin-left uk-margin-right uk-margin-remove-left@m
      "
    >
      <Goal></Goal>
      <div class="uk-margin-top" v-show="TDEE > 0">
        <h4 class="uk-heading-line uk-heading-bullet uk-text-left">
          <span>營養素</span>
        </h4>
        <div>
          <ul uk-accordion="multiple: true" class="uk-text-left">
            <li class="uk-open">
              <a class="uk-accordion-title" href="#">常用分配</a>
              <div class="uk-accordion-content">
                <span class="uk-label uk-label-success"
                  >(50%碳水 / 30%蛋白質 / 20%脂肪)</span
                >
                <Nutrition
                  :calorieGoal="calorieGoal"
                  :carbsPercent="50"
                  :porteinPercent="30"
                ></Nutrition>
              </div>
            </li>
            <li>
              <a class="uk-accordion-title" href="#">生酮飲食</a>
              <div class="uk-accordion-content">
                <span class="uk-label uk-label-success"
                  >(10%碳水 / 20%蛋白質 / 70%脂肪)</span
                >
                <Nutrition
                  :calorieGoal="calorieGoal"
                  :carbsPercent="10"
                  :porteinPercent="20"
                ></Nutrition>
              </div>
            </li>
            <li>
              <a class="uk-accordion-title" href="#">簡易碳循環</a>
              <div class="uk-accordion-content">
                <p>高碳日</p>
                <span class="uk-label uk-label-danger"
                  >(60%碳水 / 30%蛋白質 / 10%脂肪)</span
                >
                <Nutrition
                  :calorieGoal="calorieGoal"
                  :carbsPercent="60"
                  :porteinPercent="30"
                ></Nutrition>
                <p>中碳日</p>
                <span class="uk-label uk-label-warning"
                  >(50%碳水 / 30%蛋白質 / 20%脂肪)</span
                >
                <Nutrition
                  :calorieGoal="calorieGoal"
                  :carbsPercent="50"
                  :porteinPercent="30"
                ></Nutrition>
                <p>低碳日</p>
                <span class="uk-label uk-label-success"
                  >(20%碳水 / 40%蛋白質 / 40%脂肪)</span
                >
                <Nutrition
                  :calorieGoal="calorieGoal"
                  :carbsPercent="20"
                  :porteinPercent="40"
                ></Nutrition>
              </div>
            </li>
          </ul>
        </div>
        <div class="uk-text-left" v-if="!isLogin">
          <div class="uk-margin-small-bottom">
            <span class="uk-margin-small-right" uk-icon="icon: info"></span>
            <b class="uk-text-small"
              >登入後可使用完整碳循環計算，並可儲存基本信息。</b
            >
          </div>
          <button
            class="uk-button uk-button-secondary uk-width-1-1"
            @click="login"
          >
            點我登入並儲存資料
          </button>
        </div>
        <div class="uk-text-left" v-else>
          <button
            class="uk-button uk-button-secondary uk-width-1-1"
            @click="goCarbsCycling"
          >
            點我使用碳循環週期設定
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Nutrition from "../components/Nutrition.vue";
import Personal from "../components/Personal.vue";
import Goal from "../components/Goal.vue";
import { mapGetters, mapState } from "vuex";
import { googleLogin } from "../firebase";

const emptyVal = "⋯⋯";

export default defineComponent({
  name: "Home",
  components: {
    Nutrition,
    Personal,
    Goal,
  },
  computed: {
    BMI(): string {
      if (this.$store.getters.BMI > 0) {
        return this.$store.getters.BMI.toFixed(2);
      }
      return emptyVal;
    },

    BMR(): string {
      if (this.$store.getters.BMR > 0) {
        return this.$store.getters.BMR.toFixed(0);
      }
      return emptyVal;
    },
    DisplayTDEE(): string {
      if (this.TDEE > 0) {
        return this.TDEE.toFixed(0);
      }
      return emptyVal;
    },
    ...mapState([
      "weight",
      "height",
      "age",
      "gender",
      "workout",
      "isLogin",
      "goal",
      "calorie",
    ]),
    ...mapGetters(["TDEE", "calorieGoal"]),
  },
  methods: {
    login() {
      googleLogin().then((data) => {
        if (data.user) {
          this.$store
            .dispatch("updateUserData", { uid: data.user.uid })
            .then(() => this.$router.push("/carbs-cycling"))
            .catch(() => {
              // TODO del
              console.log("updateUserData catch error");
            });
        }
      });
    },
    goCarbsCycling() {
      this.$router.push("/carbs-cycling");
    },
  },
});
</script>


import { defineComponent } from "vue";
import * as T from "@/models/Nutrition";

export default defineComponent({
  name: "Nutrition",
  props: {
    calorieGoal: {
      type: Number,
      require: true,
      default: 0,
    },
    carbsPercent: {
      type: Number,
      require: true,
      default: 0,
    },
    porteinPercent: {
      type: Number,
      require: true,
      default: 0,
    },
    carbs: {
      type: Number,
    },
    portein: {
      type: Number,
    },
    fat: {
      type: Number,
    },
  },
  computed: {
    calcNutrition(): T.Nutrition {
      if (this.carbs && this.portein && this.fat) {
        return {
          carbs: this.carbs,
          portein: this.portein,
          fat: this.fat,
        };
      }
      const carbs = (this.calorieGoal * (this.carbsPercent / 100)) / 4;
      const portein = (this.calorieGoal * (this.porteinPercent / 100)) / 4;
      return {
        carbs,
        portein,
        fat: (this.calorieGoal - (carbs + portein) * 4) / 9,
      };
    },
  },
});

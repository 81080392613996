<template>
  <div class="uk-accordion-content">
    <div class="uk-child-width-1-3 uk-grid-collapse uk-grid-match" uk-grid>
      <div>
        <div class="uk-card uk-card-body uk-tile uk-tile-muted">
          <h3 class="uk-card-title card-title">碳水</h3>
          <p>
            {{ calcNutrition.carbs.toFixed(0) }}
            克
          </p>
        </div>
      </div>
      <div>
        <div class="uk-card uk-card-primary uk-card-body">
          <h3 class="uk-card-title card-title">蛋白質</h3>
          <p>{{ calcNutrition.portein.toFixed(0) }} 克</p>
        </div>
      </div>
      <div>
        <div class="uk-card uk-card-secondary uk-card-body">
          <h3 class="uk-card-title card-title">脂肪</h3>
          <p>{{ calcNutrition.fat.toFixed(0) }} 克</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import * as T from "@/models/Nutrition";

export default defineComponent({
  name: "Nutrition",
  props: {
    calorieGoal: {
      type: Number,
      require: true,
      default: 0,
    },
    carbsPercent: {
      type: Number,
      require: true,
      default: 0,
    },
    porteinPercent: {
      type: Number,
      require: true,
      default: 0,
    },
    carbs: {
      type: Number,
    },
    portein: {
      type: Number,
    },
    fat: {
      type: Number,
    },
  },
  computed: {
    calcNutrition(): T.Nutrition {
      if (this.carbs && this.portein && this.fat) {
        return {
          carbs: this.carbs,
          portein: this.portein,
          fat: this.fat,
        };
      }
      const carbs = (this.calorieGoal * (this.carbsPercent / 100)) / 4;
      const portein = (this.calorieGoal * (this.porteinPercent / 100)) / 4;
      return {
        carbs,
        portein,
        fat: (this.calorieGoal - (carbs + portein) * 4) / 9,
      };
    },
  },
});
</script>

<style scoped>
.card-title {
  font-size: 16px;
}
</style>

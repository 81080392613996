
import { defineComponent } from "vue";
import Nutrition from "../components/Nutrition.vue";
import Personal from "../components/Personal.vue";
import Goal from "../components/Goal.vue";
import { mapGetters, mapState } from "vuex";
import { googleLogin } from "../firebase";

const emptyVal = "⋯⋯";

export default defineComponent({
  name: "Home",
  components: {
    Nutrition,
    Personal,
    Goal,
  },
  computed: {
    BMI(): string {
      if (this.$store.getters.BMI > 0) {
        return this.$store.getters.BMI.toFixed(2);
      }
      return emptyVal;
    },

    BMR(): string {
      if (this.$store.getters.BMR > 0) {
        return this.$store.getters.BMR.toFixed(0);
      }
      return emptyVal;
    },
    DisplayTDEE(): string {
      if (this.TDEE > 0) {
        return this.TDEE.toFixed(0);
      }
      return emptyVal;
    },
    ...mapState([
      "weight",
      "height",
      "age",
      "gender",
      "workout",
      "isLogin",
      "goal",
      "calorie",
    ]),
    ...mapGetters(["TDEE", "calorieGoal"]),
  },
  methods: {
    login() {
      googleLogin().then((data) => {
        if (data.user) {
          this.$store
            .dispatch("updateUserData", { uid: data.user.uid })
            .then(() => this.$router.push("/carbs-cycling"))
            .catch(() => {
              // TODO del
              console.log("updateUserData catch error");
            });
        }
      });
    },
    goCarbsCycling() {
      this.$router.push("/carbs-cycling");
    },
  },
});


import { defineComponent } from "vue";
import { mapMutations, mapState } from "vuex";

export default defineComponent({
  name: "PersonalComponent",
  props: ["showSave"],
  computed: {
    weight: {
      get(): number | null {
        return this.$store.state.weight;
      },
      set(val: number) {
        this.$store.commit("setWeight", val);
      },
    },
    height: {
      get(): number | null {
        return this.$store.state.height;
      },
      set(val: number) {
        this.$store.commit("setHeight", val);
      },
    },
    age: {
      get(): number | null {
        return this.$store.state.age;
      },
      set(val: number) {
        this.$store.commit("setAge", val);
      },
    },
    ...mapState(["gender", "workout", "isLogin"]),
  },
  methods: {
    nextInput(refName: string) {
      const el = this.$refs[refName] as HTMLInputElement;
      if (el) {
        el.focus();
      }
    },
    save() {
      const w = window as any;
      this.$store
        .dispatch("updateUserData")
        .then(() => {
          w.UIkit.notification("儲存完成", {
            status: "success",
            timeout: 1000,
          });
        })
        .catch(() => {
          w.UIkit.notification("儲存失敗", { status: "danger", timeout: 1000 });
        });
    },
    ...mapMutations(["setGender", "setWorkout", "setAge"]),
  },
});

<template>
  <div class="uk-width-auto uk-text-left">
    <div>
      <div class="uk-inline uk-margin">
        <label
          class="uk-margin-small-right uk-inline uk-text-right label-width"
          for="form-height-text"
          >身高</label
        >
        <input
          class="uk-input uk-width-small"
          id="form-height-text"
          type="number"
          inputmode="decimal"
          v-model.number="height"
          @keydown.enter="nextInput('weight')"
        />
        <span class="uk-form-icon uk-form-icon-flip uk-margin-xsmall-right"
          >公分</span
        >
      </div>
    </div>

    <div>
      <div class="uk-inline uk-margin">
        <label
          class="uk-margin-small-right uk-inline uk-text-right label-width"
          for="form-weight-text"
          >體重</label
        >
        <input
          class="uk-input uk-width-small"
          id="form-weight-text"
          ref="weight"
          type="number"
          inputmode="decimal"
          v-model.number="weight"
          @keydown.enter="nextInput('age')"
        />
        <span class="uk-form-icon uk-form-icon-flip uk-margin-xsmall-right"
          >公斤</span
        >
      </div>
    </div>

    <div>
      <div class="uk-inline uk-margin">
        <label
          class="uk-margin-small-right uk-inline uk-text-right label-width"
          for="form-age-text"
          >年齡</label
        >
        <input
          class="uk-input uk-width-small"
          id="form-age-text"
          ref="age"
          type="number"
          inputmode="decimal"
          v-model.number="age"
        />
        <span class="uk-form-icon uk-form-icon-flip uk-margin-xsmall-right"
          >歲</span
        >
      </div>
    </div>

    <div>
      <div class="uk-inline uk-margin">
        <label
          class="uk-margin-small-right uk-inline uk-text-right label-width"
        >
          性別</label
        >
        <div class="uk-button-group">
          <button
            class="uk-button"
            type="button"
            @click="setGender(1)"
            :class="{
              'uk-button-default': gender != 1,
              'uk-button-secondary': gender === 1,
            }"
          >
            男
          </button>
          <button
            class="uk-button"
            type="button"
            @click="setGender(2)"
            :class="{
              'uk-button-default': gender != 2,
              'uk-button-secondary': gender === 2,
            }"
          >
            女
          </button>
        </div>
      </div>
    </div>

    <div class="uk-grid-collapse" uk-grid>
      <div class="uk-width-auto">
        <label
          class="
            uk-margin-small-right uk-margin-small-top uk-inline uk-text-right
            label-width
          "
          >運動頻率</label
        >
      </div>
      <div class="uk-width-expand">
        <div>
          <button
            class="uk-button button-width"
            type="button"
            @click="setWorkout(1.2)"
            :class="{
              'uk-button-default': workout != 1.2,
              'uk-button-secondary': workout === 1.2,
            }"
          >
            幾乎沒運動
          </button>
        </div>
        <div>
          <button
            class="uk-button border-top-none button-width"
            type="button"
            @click="setWorkout(1.375)"
            :class="{
              'uk-button-default': workout != 1.375,
              'uk-button-secondary': workout === 1.375,
            }"
          >
            每週 1 - 2 天
          </button>
        </div>
        <div>
          <button
            class="uk-button border-top-none button-width"
            type="button"
            @click="setWorkout(1.55)"
            :class="{
              'uk-button-default': workout != 1.55,
              'uk-button-secondary': workout === 1.55,
            }"
          >
            每週 3 - 4 天
          </button>
        </div>
        <div>
          <button
            class="uk-button border-top-none button-width"
            type="button"
            @click="setWorkout(1.725)"
            :class="{
              'uk-button-default': workout != 1.725,
              'uk-button-secondary': workout === 1.725,
            }"
          >
            每週 5 - 6 天
          </button>
        </div>
        <div>
          <button
            class="
              uk-button
              border-top-none
              button-width
              uk-padding-remove-horizontal
            "
            type="button"
            @click="setWorkout(1.9)"
            :class="{
              'uk-button-default': workout != 1.9,
              'uk-button-secondary': workout === 1.9,
            }"
          >
            每天高強度運動
          </button>
        </div>
      </div>
    </div>

    <div class="uk-margin-large uk-margin-large-left uk-text-right" v-if="showSave">
      <button class="uk-button uk-button-secondary button-width" @click="save">
        儲存
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapMutations, mapState } from "vuex";

export default defineComponent({
  name: "PersonalComponent",
  props: ["showSave"],
  computed: {
    weight: {
      get(): number | null {
        return this.$store.state.weight;
      },
      set(val: number) {
        this.$store.commit("setWeight", val);
      },
    },
    height: {
      get(): number | null {
        return this.$store.state.height;
      },
      set(val: number) {
        this.$store.commit("setHeight", val);
      },
    },
    age: {
      get(): number | null {
        return this.$store.state.age;
      },
      set(val: number) {
        this.$store.commit("setAge", val);
      },
    },
    ...mapState(["gender", "workout", "isLogin"]),
  },
  methods: {
    nextInput(refName: string) {
      const el = this.$refs[refName] as HTMLInputElement;
      if (el) {
        el.focus();
      }
    },
    save() {
      const w = window as any;
      this.$store
        .dispatch("updateUserData")
        .then(() => {
          w.UIkit.notification("儲存完成", {
            status: "success",
            timeout: 1000,
          });
        })
        .catch(() => {
          w.UIkit.notification("儲存失敗", { status: "danger", timeout: 1000 });
        });
    },
    ...mapMutations(["setGender", "setWorkout", "setAge"]),
  },
});
</script>

<style scoped>
.label-width {
  width: 110px;
}
.button-width {
  width: 150px;
  min-width: 150px;
}
.border-top-none {
  border-top: none;
}
</style>

interface UserData {
  weight: number | null;
  height: number | null;
  age: number | null;
  gender: number;
  workout: number;
  goal: number;
  calorie: number;
  highCarbsDay: Array<number>;
  mediumCarbsDay: Array<number>;
  lowCarbsDay: Array<number>;
  porteinMultiple: number;
}

export const getDefaultUserData = (): UserData => {
  return {
    weight: null,
    height: null,
    age: null,
    gender: 0,
    workout: -1.0,
    goal: 0,
    calorie: 0,
    highCarbsDay: [2, 5],
    mediumCarbsDay: [3, 6],
    lowCarbsDay: [1, 4, 7],
    porteinMultiple: 2,
  };
};

export default UserData;


import { defineComponent } from "vue";
import { mapState } from "vuex";
import { googleLogin, logout } from "../firebase";

export default defineComponent({
  name: "TopComponent",
  props: {
    title: String,
  },
  computed: {
    ...mapState(["isLogin"]),
  },
  methods: {
    loginOrLogout() {
      if (this.isLogin) {
        logout().then(() => {
          this.$router.push("/");
        });
      } else {
        googleLogin().then((data) => {
          if (data.user) {
            this.$store
              .dispatch("updateUserData", { uid: data.user.uid })
              .then(() => {
                this.$router.push("/carbs-cycling");
              })
              .catch(() => {
                this.$router.push("/carbs-cycling");
              });
          }
        });
      }
    },
  },
});

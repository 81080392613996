<template>
  <div>
    <div>
      <h4 class="uk-heading-line uk-heading-bullet uk-text-left">
        <span>體態目標</span>
      </h4>
      <div class="uk-button-group uk-width-1-1 uk-child-width-expand">
        <button
          class="uk-button"
          type="button"
          @click="setGoal(-1)"
          :class="{
            'uk-button-default': goal != -1,
            'uk-button-secondary': goal === -1,
          }"
        >
          減脂
        </button>
        <button
          class="uk-button"
          type="button"
          @click="setGoal(0)"
          :class="{
            'uk-button-default': goal != 0,
            'uk-button-secondary': goal === 0,
          }"
        >
          維持
        </button>
        <button
          class="uk-button"
          type="button"
          @click="setGoal(1)"
          :class="{
            'uk-button-default': goal != 1,
            'uk-button-secondary': goal === 1,
          }"
        >
          增肌
        </button>
      </div>
    </div>
    <div class="uk-margin-medium-top" v-if="goal === 0">
      <div>
        <span class="uk-label uk-label-danger"
          >目標 {{ TDEE.toFixed(0) }} 卡</span
        >
      </div>
    </div>
    <div class="uk-margin-medium-top" v-else>
      <h4 class="uk-heading-line uk-heading-bullet uk-text-left">
        <span>熱量{{ goal > 0 ? "盈餘" : "赤字" }}</span>
      </h4>
      <div>TDEE {{ TDEE.toFixed(0) }}</div>
      <div>{{ goal > 0 ? "加" : "減" }}</div>
      <div
        class="
          uk-button-group uk-width-1-1 uk-child-width-expand uk-margin-small-top
        "
      >
        <template v-for="item in [100, 200, 300, 400, 500]" :key="item">
          <button
            class="uk-button uk-padding-remove-horizontal"
            type="button"
            @click="setCalorie(item)"
            :class="{
              'uk-button-default': calorie != item,
              'uk-button-secondary': calorie === item,
            }"
          >
            {{ item }}
          </button>
        </template>
      </div>
      <div>=</div>
      <div class="uk-margin-small-top">
        <span class="uk-label uk-label-danger"
          >目標 {{ $store.getters.calorieGoal.toFixed(0) }} 卡</span
        >
      </div>
    </div>
    <template v-if="showCarbCycling">
      <h4 class="uk-heading-line uk-heading-bullet uk-text-left">
        <span>每日蛋白質目標</span>
      </h4>
      <div>
        <div>
          體重
          <span class="uk-margin-small-left uk-margin-small-right">{{
            weight
          }}</span>
          <span class="uk-margin-small-right">x</span>
          <div class="uk-inline">
            <input
              class="uk-input uk-text-center min-input"
              type="number"
              inputmode="decimal"
              v-model.number="porteinMultiple"
            />
            <span class="uk-form-icon uk-form-icon-flip uk-margin-xsmall-right"
              >倍</span
            >
          </div>
          <span class="uk-margin-small-left uk-margin-small-right">=</span>
          <div class="uk-inline">
            <input
              class="uk-input uk-text-center min-input"
              type="number"
              inputmode="decimal"
              v-model.number="porteinGoal"
            />
            <span class="uk-form-icon uk-form-icon-flip uk-margin-xsmall-right"
              >克</span
            >
          </div>
        </div>
      </div>
      <h4 class="uk-heading-line uk-heading-bullet uk-text-left">
        <span>碳循環週期設定</span>
      </h4>
      <div>
        高碳日<span class="uk-text-small"> (建議2天) </span>
        <div class="uk-button-group">
          <template v-for="item in 7" :key="item">
            <button
              class="
                uk-button
                uk-button-default
                uk-padding-small
                uk-padding-remove-vertical
              "
              type="button"
              @click="setCrobDay({ name: 'highCarbsDay', day: item })"
              :class="{
                'uk-button-default': !highCarbsDay.includes(item),
                'uk-button-secondary': highCarbsDay.includes(item),
              }"
            >
              {{ displayDay(item) }}
            </button>
          </template>
        </div>
        <hr />
        中碳日<span class="uk-text-small"> (建議2天) </span>
        <div class="uk-button-group">
          <template v-for="item in 7" :key="item">
            <button
              class="
                uk-button
                uk-button-default
                uk-padding-small
                uk-padding-remove-vertical
              "
              type="button"
              @click="setCrobDay({ name: 'mediumCarbsDay', day: item })"
              :class="{
                'uk-button-default': !mediumCarbsDay.includes(item),
                'uk-button-secondary': mediumCarbsDay.includes(item),
              }"
            >
              {{ displayDay(item) }}
            </button>
          </template>
        </div>
        <hr />
        低碳日<span class="uk-text-small"> (建議3天) </span>
        <div class="uk-button-group">
          <template v-for="item in 7" :key="item">
            <button
              class="
                uk-button
                uk-button-default
                uk-padding-small
                uk-padding-remove-vertical
              "
              type="button"
              @click="setCrobDay({ name: 'lowCarbsDay', day: item })"
              :class="{
                'uk-button-default': !lowCarbsDay.includes(item),
                'uk-button-secondary': lowCarbsDay.includes(item),
              }"
            >
              {{ displayDay(item) }}
            </button>
          </template>
        </div>
      </div>
      <hr />
      <div class="uk-text-danger" v-if="carbsCyclingDays !== 7">
        <p>您僅設定了 {{ carbsCyclingDays }} 天</p>
        <p>
          週{{
            [1, 2, 3, 4, 5, 6, 7]
              .filter(
                (p) =>
                  ![
                    ...highCarbsDay,
                    ...mediumCarbsDay,
                    ...lowCarbsDay,
                  ].includes(p)
              )
              .map((p) => displayDay(p))
              .join("、")
          }}尚未設定
        </p>
      </div>
    </template>
  </div>
</template>

<style scoped>
.min-input {
  width: 90px;
  padding-right: 20px;
}
</style>

<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters, mapMutations, mapState } from "vuex";

export default defineComponent({
  computed: {
    porteinGoal: {
      get(): number {
        return this.$store.getters.porteinGoal;
      },
      set(val: number) {
        if (this.$store.state.weight) {
          this.$store.commit(
            "setPorteinMultiple",
            val ? val / this.$store.state.weight : 0
          );
        }
      },
    },
    porteinMultiple: {
      get(): number {
        return Number(this.$store.state.porteinMultiple.toFixed(2));
      },
      set(val: number) {
        this.$store.commit("setPorteinMultiple", val ? val : 0);
      },
    },
    ...mapState([
      "goal",
      "calorie",
      "highCarbsDay",
      "mediumCarbsDay",
      "lowCarbsDay",
      "weight",
    ]),
    ...mapGetters(["TDEE", "carbsCyclingDays"]),
  },
  props: {
    showCarbCycling: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    displayDay(val: number): string {
      const days = ["", "一", "二", "三", "四", "五", "六", "日"];
      if (days.length > val) {
        return days[val];
      }
      return "";
    },
    ...mapMutations(["setGoal", "setCalorie", "setCrobDay"]),
  },
});
</script>

<template>
  <router-view />
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>

<script lang="ts">
import { defineComponent } from "vue";
import { auth } from "./firebase";

export default defineComponent({
  beforeMount() {
    auth.onAuthStateChanged((user) => {
      this.$store.dispatch("authStateChanged", user);
    });
  },
});
</script>

import UserData, { getDefaultUserData } from "./UserData";
import firebase from "firebase/app";

interface State extends UserData {
  user: firebase.User | null;
  isLogin: boolean;
}

export const getDefaultState = (): State => {
  const obj = Object.assign(getDefaultUserData(), {
    user: null,
    isLogin: false,
  });
  return obj;
};

export default State;

import * as T from "@/models/Nutrition";

function CalcBMI(weight: number, height: number): number {
  return weight / (height / 100) ** 2;
}

function CalcBMR(
  weight: number,
  height: number,
  age: number,
  gender: number
): number {
  // age = 1, ageWeight = 5
  // age = 2, ageWeight = -161
  let genderWeight = 5;
  if (gender === 2) {
    genderWeight = -161;
  }
  return 10 * weight + 6.25 * height - 5 * age + genderWeight;
}

function CalcTDEE(BMR: number, workout: number): number {
  return BMR * workout;
}

function CalcCarbsCyclingNutrition(
  highCarbsDays: number,
  mediumCarbsDays: number,
  lowCarbsDays: number,
  carbsOfDay: number,
  porteinOfDay: number,
  fatOfDay: number
): Array<T.Nutrition | null> {
  if (highCarbsDays + mediumCarbsDays + lowCarbsDays < 7) {
    const obj: T.Nutrition = { portein: 0, carbs: 0, fat: 0 };
    return [obj, obj, obj];
  }
  return [
    {
      portein: porteinOfDay * carbsCyclingPercent[0].portein,
      carbs: (carbsOfDay * 7 * carbsCyclingPercent[0].carbs) / highCarbsDays,
      fat: (fatOfDay * 7 * carbsCyclingPercent[0].fat) / highCarbsDays,
    },
    {
      portein: porteinOfDay * carbsCyclingPercent[1].portein,
      carbs: (carbsOfDay * 7 * carbsCyclingPercent[1].carbs) / mediumCarbsDays,
      fat: (fatOfDay * 7 * carbsCyclingPercent[1].fat) / mediumCarbsDays,
    },
    {
      portein: porteinOfDay * carbsCyclingPercent[2].portein,
      carbs: (carbsOfDay * 7 * carbsCyclingPercent[2].carbs) / lowCarbsDays,
      fat: (fatOfDay * 7 * carbsCyclingPercent[2].fat) / lowCarbsDays,
    },
  ];
}

export const porteinCalorie = 4;
export const carbsCalorie = 4;
export const fatCalorie = 9;
export const fatPercent = 0.85;
export const carbsCyclingPercent: Array<T.Nutrition> = [
  {
    carbs: 0.5,
    portein: 1,
    fat: 0.15,
  },
  {
    carbs: 0.35,
    portein: 1,
    fat: 0.25,
  },
  {
    carbs: 0.15,
    portein: 1,
    fat: 0.6,
  },
];
export default {
  CalcBMI,
  CalcBMR,
  CalcTDEE,
  CalcCarbsCyclingNutrition,
};

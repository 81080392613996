import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";
import UserData from "./models/UserData";

const firebaseConfig = {
  apiKey: "AIzaSyDsDr-VTnqqInNABVZ3Nk__5RsDjl2mgOA",
  authDomain: "www.bitbitcode.com",
  projectId: "bitbitcode-6e210",
  storageBucket: "bitbitcode-6e210.appspot.com",
  messagingSenderId: "140739126794",
  appId: "1:140739126794:web:addf467a99c582cd1961f3",
  measurementId: "G-LV497M2XW3",
};

const isProduction = process.env.NODE_ENV === "production";

firebase.initializeApp(firebaseConfig);
if (isProduction) {
  firebase.analytics();
}

const auth = firebase.auth();
const firestore = firebase.firestore();
const googleLogin = () => {
  return auth.signInWithPopup(new firebase.auth.GoogleAuthProvider());
};
const logout = () => {
  return auth.signOut();
};
const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
      const unsubscribe = firebase.auth().onAuthStateChanged(user => {
          unsubscribe();
          resolve(user);
      }, reject);
  });
};
const getUserData = (id: string) => {
  return firestore.collection("users").doc(id).get();
};

const updateUserData = (id: string, data: UserData) => {
  return firestore.collection("users").doc(id).set(data);
};

export { auth, firestore, googleLogin, logout, getCurrentUser, getUserData, updateUserData };

<template>
  <div class="uk-tile uk-tile-secondary uk-padding-small">
    <span>營養素計算機</span><span v-if="title"> - {{ title }}</span>
    <a
      id="offcanvas-button"
      class="uk-position-small uk-position-center-right"
      uk-icon="user"
      uk-toggle="target: #offcanvas-usage"
    ></a>
  </div>

  <div id="offcanvas-usage" uk-offcanvas="overlay: true">
    <div class="uk-offcanvas-bar uk-text-left">
      <button class="uk-offcanvas-close" type="button" uk-close></button>
      <ul class="uk-nav uk-nav-default">
        <li class="uk-nav-header uk-margin-bottom">功能表</li>
        <template v-if="isLogin">
          <li>
            <router-link to="/carbs-cycling"
              ><span class="uk-margin-small-right" uk-icon="icon: future"></span
              >碳循環週期設定</router-link
            >
          </li>
          <li>
            <router-link to="/carbs-cycling-rule"
              ><span
                class="uk-margin-small-right"
                uk-icon="icon: bookmark"
              ></span
              >碳循環計算規則</router-link
            >
          </li>
          <li class="uk-nav-divider uk-margin-top"></li>
        </template>
        <li>
          <router-link to="/"
            ><span
              class="uk-margin-small-right uk-margin-top"
              uk-icon="icon: home"
            ></span
            >回首頁</router-link
          >
        </li>
        <li>
          <router-link to="/about"
            ><span class="uk-margin-small-right" uk-icon="icon: info"></span
            >關於本站</router-link
          >
        </li>
      </ul>
      <button
        class="
          uk-button
          uk-button-default
          uk-position-medium
          uk-position-bottom-center
        "
        @click="loginOrLogout"
      >
        Google {{ isLogin ? "登出" : "登入" }}
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapState } from "vuex";
import { googleLogin, logout } from "../firebase";

export default defineComponent({
  name: "TopComponent",
  props: {
    title: String,
  },
  computed: {
    ...mapState(["isLogin"]),
  },
  methods: {
    loginOrLogout() {
      if (this.isLogin) {
        logout().then(() => {
          this.$router.push("/");
        });
      } else {
        googleLogin().then((data) => {
          if (data.user) {
            this.$store
              .dispatch("updateUserData", { uid: data.user.uid })
              .then(() => {
                this.$router.push("/carbs-cycling");
              })
              .catch(() => {
                this.$router.push("/carbs-cycling");
              });
          }
        });
      }
    },
  },
});
</script>

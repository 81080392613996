import { createStore } from "vuex";
import { getUserData, updateUserData } from "@/firebase";
import calc, {
  carbsCalorie,
  porteinCalorie,
  fatCalorie,
  fatPercent,
} from "../utils/calc";
import State, { getDefaultState } from "@/models/State";
import { getDefaultUserData } from "@/models/UserData";

const state: State = getDefaultState();

export default createStore({
  state,
  getters: {
    BMI(state): number {
      if (state.weight && state.height) {
        return calc.CalcBMI(state.weight, state.height);
      }
      return 0;
    },
    BMR(state): number {
      if (state.weight && state.height && state.age && state.gender) {
        return calc.CalcBMR(
          state.weight,
          state.height,
          state.age,
          state.gender
        );
      }
      return 0;
    },
    TDEE(state, getters): number {
      return Number((getters.BMR * state.workout).toFixed(0));
    },
    carbsGoal(state, getters): number {
      if (state.weight) {
        return Number(
          (
            (getters.calorieGoal -
              (getters.porteinGoal * porteinCalorie +
                getters.fatGoal * fatCalorie)) /
            carbsCalorie
          ).toFixed(0)
        );
      }
      return 0;
    },
    porteinGoal(state): number {
      if (state.weight) {
        return Number((state.weight * state.porteinMultiple).toFixed(0));
      }
      return 0;
    },
    fatGoal(state): number {
      if (state.weight) {
        return Number((state.weight * fatPercent).toFixed(0));
      }
      return 0;
    },
    calorieGoal(state, getters): number {
      return Number((state.goal * state.calorie + getters.TDEE).toFixed(0));
    },
    carbsCyclingDays(state): number {
      return (
        state.highCarbsDay.length +
        state.mediumCarbsDay.length +
        state.lowCarbsDay.length
      );
    },
  },
  mutations: {
    authStateChanged(state, user) {
      if (user) {
        state.user = user;
        state.isLogin = true;
      } else {
        Object.assign(state, getDefaultState());
      }
    },
    setUserData(state, data) {
      Object.assign(state, data);
    },
    setPorteinMultiple(state, porteinMultiple: number) {
      state.porteinMultiple = porteinMultiple;
    },
    setWeight(state, weight: number) {
      state.weight = weight;
    },
    setHeight(state, height: number) {
      state.height = height;
    },
    setAge(state, age: number) {
      state.age = age;
    },
    setWorkout(state, workout: number) {
      state.workout = workout;
    },
    setGoal(state, goal: number) {
      state.goal = goal;
    },
    setGender(state, gender: number) {
      state.gender = gender;
    },
    setCalorie(state, calorie: number) {
      state.calorie = calorie;
    },
    setCrobDay(state, payload: { name: string; day: number }) {
      const porteinDayArray = ["highCarbsDay", "mediumCarbsDay", "lowCarbsDay"];
      porteinDayArray.forEach((key) => {
        if (state[key].includes(payload.day)) {
          state[key] = state[key].filter((item: number) => item != payload.day);
        } else if (key === payload.name) {
          state[key].push(payload.day);
        }
      });
    },
  },
  actions: {
    authStateChanged({ commit, dispatch }, user) {
      commit("authStateChanged", user);
      dispatch("getUserData");
    },
    getUserData({ state, commit }) {
      if (state.user) {
        getUserData(state.user.uid).then((doc) => {
          if (doc.exists) {
            commit("setUserData", doc.data());
          }
        });
      }
    },
    updateUserData({ state }, payload) {
      let uid: string | undefined = state.user?.uid;
      if (payload && payload.uid) {
        uid = payload.uid;
      }
      if (uid && state.weight && state.height && state.age) {
        const data = getDefaultUserData();
        for (const key in data) {
          if (Object.prototype.hasOwnProperty.call(state, key)) {
            data[key] = state[key];
          }
        }

        return updateUserData(uid, data);
      }
      return new Promise((resolve, reject) => reject());
    },
  },
  modules: {},
});

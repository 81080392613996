
import { defineComponent } from "vue";
import { auth } from "./firebase";

export default defineComponent({
  beforeMount() {
    auth.onAuthStateChanged((user) => {
      this.$store.dispatch("authStateChanged", user);
    });
  },
});

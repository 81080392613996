
import { defineComponent } from "vue";
import { mapGetters, mapMutations, mapState } from "vuex";

export default defineComponent({
  computed: {
    porteinGoal: {
      get(): number {
        return this.$store.getters.porteinGoal;
      },
      set(val: number) {
        if (this.$store.state.weight) {
          this.$store.commit(
            "setPorteinMultiple",
            val ? val / this.$store.state.weight : 0
          );
        }
      },
    },
    porteinMultiple: {
      get(): number {
        return Number(this.$store.state.porteinMultiple.toFixed(2));
      },
      set(val: number) {
        this.$store.commit("setPorteinMultiple", val ? val : 0);
      },
    },
    ...mapState([
      "goal",
      "calorie",
      "highCarbsDay",
      "mediumCarbsDay",
      "lowCarbsDay",
      "weight",
    ]),
    ...mapGetters(["TDEE", "carbsCyclingDays"]),
  },
  props: {
    showCarbCycling: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    displayDay(val: number): string {
      const days = ["", "一", "二", "三", "四", "五", "六", "日"];
      if (days.length > val) {
        return days[val];
      }
      return "";
    },
    ...mapMutations(["setGoal", "setCalorie", "setCrobDay"]),
  },
});
